import { Component, OnInit } from '@angular/core';
import { DatepickerOptions } from 'ng2-datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/ja';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-order-list-detail',
  templateUrl: './order-list-detail.component.html',
  styleUrls: ['./order-list-detail.component.scss'],
})
export class OrderListDetailComponent implements OnInit {
  public order_id = '';
  public order_date = '';
  public deposit_date = '';
  public depositDate = '';
  public order_type = '';
  public company_name = '';
  public company_email = '';
  public order_staff = '';
  public payment_status = '';
  public coupon_flg = '0';
  public order_quotation = '';
  public order_invoice = '';
  public cancel_flg = '';
  public order_cancel_info = '';
  public ticket_info;
  public ticket_total = '';
  public bill_tax = '';
  public bill_tax_free = '';
  public order_types = [
    { name: '本部', number: 1 },
    { name: '企業', number: 2 },
    { name: '個人', number: 3 }
  ];
  public TicketItem = [];

  public errorTitle = '';
  public errorMessage = '';
  public completeTitle = '';
  public completeMessage = '';
  public order_cancel_info_err = '';
  public deposit_date_err = '';

  constructor(
    private router: Router,
    public data: DataProvider,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-order-list';
    this.order_id = this.route.snapshot.paramMap.get('order_id');
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        var pages = res.data;
        const page = pages.find(page => page.pagename === 'wf-order-list-detail');
        var bool = page ? page.auth === "F" : false;
        if (!bool) {
          this.router.navigate(['/']);
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
    if (this.order_id) {
      this.getCoupon();
    }
  }
  
  getCoupon(){
    this.spinner.show();
    this.rest.Get_CouponOrderId(this.data.uuid, this.order_id).subscribe(
      (res) => {
        console_log(res);
        this.order_id = res.data.order_id;
        this.order_date = res.data.order_date;
        this.order_type = res.data.order_type;
        this.company_name = res.data.company_name;
        this.company_email = res.data.company_email;
        this.order_staff = res.data.order_staff;
        this.payment_status = res.data.payment_status;
        this.coupon_flg = res.data.coupon_flg;
        this.order_quotation = res.data.order_quotation;
        this.order_invoice = res.data.order_invoice;
        this.cancel_flg = res.data.cancel_flg;
        this.order_cancel_info = res.data.order_cancel_info;
        this.ticket_info = res.data.ticket_info;
        this.TicketItem = res.data.ticket_info.TicketItem;
        this.ticket_total = res.data.ticket_info.ticket_total;
        this.bill_tax = res.data.ticket_info.bill_tax;
        this.bill_tax_free = res.data.ticket_info.bill_tax_free;
        this.deposit_date = res.data.deposit_date;
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
  }

  back() {
    this.router.navigate(['/wf-order-list']);
  }

  cancel(value) {
    if (value == 'show') {
      document.getElementById('modal-cancel').style.display = 'block';
    } else if (value == 'next') {
      if (this.order_cancel_info != '') {
        this.order_cancel_info_err = '';
        this.spinner.show();
        this.rest.Update_CouponOrder_Cancel(
          this.data.uuid,
          this.order_id,
          this.order_cancel_info
        ).subscribe(
          (res) => {
            console_log(res);
            document.getElementById('modal-cancel').style.display = 'none';
            this.completeTitle = '取消完了';
            this.completeMessage = '取消が完了しました。';
            document.getElementById('modal-complete').style.display = 'block';
            this.spinner.hide();
          },
          (err) => {
            console_log(err);
            document.getElementById('modal-cancel').style.display = 'none';
            this.errorTitle = '取消失敗';
            this.translate.get('ERROR.' + err.error.error.code).subscribe((res) => (this.errorMessage = res));
            document.getElementById('modal-error').style.display = 'block';
            this.spinner.hide();
          }
        );
      } else {
        this.order_cancel_info_err = '取消理由が設定されていません';
      }
    } else {
      this.order_cancel_info_err = '';
      document.getElementById('modal-cancel').style.display = 'none';
    }
  }

  payment(value) {
    if (this.payment_status == '0' && this.cancel_flg == '0') {
      if (value == 'show') {
        document.getElementById('modal-payment').style.display = 'block';
      } else if (value == 'next') {
        if (this.depositDate != '') {
          this.deposit_date_err = '';
          this.spinner.show();
          this.rest.Update_CouponOrder_PaymentStatus(
            this.data.uuid,
            this.order_id,
            this.datepipe.transform(this.depositDate, 'yyyyMMdd')
          ).subscribe(
            (res) => {
              console_log(res);
              document.getElementById('modal-payment').style.display = 'none';
              this.completeTitle = '入金消込完了';
              this.completeMessage = '消込が完了しました。';
              document.getElementById('modal-complete').style.display = 'block';
              this.spinner.hide();
            },
            (err) => {
              console_log(err);
              document.getElementById('modal-payment').style.display = 'none';
              this.errorTitle = '入金消込失敗';
              this.translate.get('ERROR.' + err.error.error.code).subscribe((res) => (this.errorMessage = res));
              document.getElementById('modal-error').style.display = 'block';
              this.spinner.hide();
            }
          );
        } else {
          this.deposit_date_err = '入金日を設定してください。';
        }
      } else {
        this.deposit_date_err = '';
        document.getElementById('modal-payment').style.display = 'none';
      }
    }
  }

  code(value) {
    if (this.payment_status == '1' && this.coupon_flg == '0' && this.cancel_flg == '0') {
      if (value == 'show') {
        document.getElementById('modal-code').style.display = 'block';
      } else if (value == 'next') {  
        this.spinner.show();
        this.rest.Create_Coupon(
          this.data.uuid,
          this.order_id
        ).subscribe(
          (res) => {
            console_log(res);
            document.getElementById('modal-code').style.display = 'none';
            this.completeTitle = 'コード発行完了';
            this.completeMessage = 'コードを発行しました。';
            document.getElementById('modal-complete').style.display = 'block';
            this.spinner.hide();
          },
          (err) => {
            console_log(err);
            document.getElementById('modal-code').style.display = 'none';
            this.errorTitle = 'コード発行失敗';
            this.translate.get('ERROR.' + err.error.error.code).subscribe((res) => (this.errorMessage = res));
            document.getElementById('modal-error').style.display = 'block';
            this.spinner.hide();
          }
        );
      } else {
        document.getElementById('modal-code').style.display = 'none';
      }
    }
  }

  modalComplete() {
    document.getElementById('modal-complete').style.display = 'none';
    this.getCoupon();
  }

  modalError() {
    document.getElementById('modal-error').style.display = 'none';
    this.getCoupon();
  }

  getOrderTypeName(number: string): string | undefined {
    const orderType = this.order_types.find(type => type.number === Number(number));
    return orderType ? orderType.name : undefined;
  }

  getPaymentStatusName(number: string){
    return number == '0' ? '未入金' : '入金済み';
  }

  getCouponflgName(number: string){
    return number == '0' ? '未発行' : '発行済み';
  }

  codeList() {
    if (this.coupon_flg == '1') {
      this.router.navigate(['/wf-code-list', { order_id: this.order_id}]);
    }
  }

  couponFlgCheck() {
    if (this.cancel_flg == '1') {return true}
    if (this.payment_status == '0' && this.coupon_flg == '0') {return true}
    if (this.payment_status == '0' && this.coupon_flg == '1') {return true}
    if (this.payment_status == '1' && this.coupon_flg == '0') {return false}
    if (this.payment_status == '1' && this.coupon_flg == '1') {return true}
  }

  Calendar(id){
    if(id == 1){
      if(this.depositDate != ''){
        document.getElementById('clear1').style.display = 'block';
        document.getElementById('calendar1').style.display = 'none';
        console_log(this.depositDate);
        this.deposit_date_err = '';
      }else{
        document.getElementById('clear1').style.display = 'none';
        document.getElementById('calendar1').style.display = 'block';
        console_log(this.depositDate);
        this.deposit_date_err = '';
      }
    }
  }

  parse(e) {
    return '¥' + parseFloat(e).toLocaleString('en');
  }

  options: DatepickerOptions = {
    minYear: getYear(new Date()) - 30, // minimum available and selectable year
    maxYear: getYear(new Date()) + 30, // maximum available and selectable year
    placeholder: 'YYYY/MM/DD', // placeholder in case date model is null | undefined, example: 'Please pick a date'
    format: 'yyyy/MM/dd', // date format to display in input
    formatTitle: 'yyyy'+'年'+'MM'+'月',
    formatDays: 'EEEEE',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: locale, // date-fns locale
    position: 'bottom',
    inputClass: '', // custom input CSS class to be applied
    calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
    scrollBarColor: '#E10800', // in case you customize you theme, here you define scroll bar color
  };
}
