<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'CODE_LIST.TITLE' | translate }}</h3>  
  <!-- Section №1 -->
  <div class="content" id="detail">
    <div class="card-body" style="display: flex;">
      <p class="left-border header-text2">{{ 'CODE_LIST.TEXT_1' | translate }}</p>
      <div class="col-btn cancel_flg0" *ngIf="cancel_flg == '0'">
        <a (click)="cancel('show')" class="btn-pink btn-pink-mini">{{ 'CODE_LIST.CANCEL' | translate }}</a>
      </div>
    </div>
    <!-- 1 -->
    <div class="card-body ">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'CODE_LIST.TH_APPLY_FOR_ID' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
            <h3 class="card-title">{{order_id}}</h3>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body" *ngIf="cancel_flg == '1'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{ 'CODE_LIST.CANCELLATION' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title">{{order_cancel_info}}</h3>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_LIST.TH_APPLICATION_DATE' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title">{{order_date}}</h3>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_LIST.TH_APPLICATION_CATEGORY' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title">{{getOrderTypeName(order_type)}}</h3>
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_LIST.TH_COMPANY_NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title">{{company_name}}</h3>
        </div>
      </div>
    </div>
    <!-- 6 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_LIST.CONTACT_ADDRESS' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title">{{company_email}}</h3>
        </div>
      </div>
    </div>
    <!-- 7 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_LIST.TH_PURCHASER' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title">{{order_staff}}</h3>
        </div>
      </div>
    </div>
    <!-- 8 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <h3 class="card-title"> {{ 'CODE_LIST.1DAYPASS_PURCHASE_INFORMATION' | translate }}</h3>
        </div>
        <!-- <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title"></h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
              <h3 class="card-title">{{ticket_total}}{{ 'CODE_APPLY.PIECES' | translate }}</h3>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 9 -->
     
    <div class="card-body">
      <section class="content">
        <div class="container-fluid">
          <div class="row" style="max-width: 1000px;">
            <table class="table item-table confirm-table">
              <thead>
                <tr class="text-center">
                  <th>{{ 'CODE_APPLY.TH_PRODUCT_NAME' | translate }}</th>
                  <th>{{ 'CODE_APPLY.TH_UNIT_PRICE' | translate }} <span>{{ 'CODE_APPLY.TAX' | translate }}</span></th>
                  <th>{{ 'CODE_APPLY.TH_PIECES' | translate }}</th>
                  <th>{{ 'CODE_APPLY.TH_SUBTOTAL' | translate }} <span>{{ 'CODE_APPLY.TAX' | translate }}</span></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of TicketItem; let i = index" class="text-center">
                  <td>{{ item.ticket_title }}</td>
                  <td>{{ parse(item.bill_unit_price_tax) }}</td>
                  <td *ngIf="item.set_flg == '0'">{{ item.ticket_num}}{{ 'CODE_APPLY.PIECES' | translate }}</td>
                  <td *ngIf="item.set_flg == '1'">{{ item.ticket_num}}{{ 'CODE_APPLY.SET' | translate }}</td>
                  <td>{{ parse(item.bill_amount_tax) }}</td>
                </tr>
              </tbody>
            </table>
            <div class="total">
              <div class="total-piece">
                <h3 class="card-title"> {{ 'CODE_APPLY.TOTAL_PURCHASE_QUANTITY' | translate }}</h3>
                <h3 class="card-title"><span>{{ticket_info.ticket_total}}{{ 'CODE_APPLY.PIECES' | translate }}</span></h3>
              </div>
              <div class="total-amount">
                <h3 class="card-title"> {{ 'CODE_APPLY.TOTAL_AMOUNT' | translate }}{{ 'CODE_APPLY.TAX' | translate }}</h3>
                <h3 class="card-title" style="padding-right: 0px;"><span>{{parse(ticket_info.bill_tax)}}</span></h3>
                <!-- <h3 class="card-title bill-tax">(税込{{parse(ticket_info.bill_tax)}})</h3> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_APPLY.BREAKDOWN' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="row" *ngFor="let item of TicketItem">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">{{item.ticket_title}}</h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
              <h3 class="card-title">{{item.ticket_num}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 10 -->
    <!-- <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_LIST.CHARGE_AMOUNT' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title">{{bill_tax_free}}円(税込み{{bill_tax}}円)</h3>
        </div>
      </div>
    </div> -->
    <!-- 11 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_LIST.TH_PAYMENT_STATUS' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title">{{getPaymentStatusName(payment_status)}}</h3>
          <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-meaning col-btn">
            <a (click)="payment('show')" class="btn-pink btn-pink-mini" [ngClass]="{'btn-pink-disable': (payment_status == '1' || cancel_flg == '1')}">
              {{ 'CODE_LIST.RECEIPT_WRITE_OFF' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- deposit_date -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_LIST.TH_DEPOSIT_DATE' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title">{{deposit_date}}</h3>
        </div>
      </div>
    </div>
    <!-- 12 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_LIST.TH_CODE_ISSUE_STATUS' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title">{{getCouponflgName(coupon_flg)}}</h3>
          <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-meaning col-btn">
            <a (click)="code('show')" class="btn-pink btn-pink-mini" [ngClass]="{'btn-pink-disable': couponFlgCheck()}">
              {{ 'CODE_LIST.CODE_ISSUE' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 13 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_LIST.TH_CODE' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <a class="btn-pdf pdf-text" [ngClass]="{'btn-pdf-disable': coupon_flg == '0'}" (click)="codeList()">{{ 'CODE_LIST.LIST' | translate }}</a>
        </div>
      </div>
    </div>
    <!-- Button -->
    <div class="text-center back-btn">
      <div class="display">
        <a (click)="back()" class="btn-grey">{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
        </a>
      </div>
    </div>
    <!-- End Button -->
  </div>
</div>
<!-- modals -->
 <!-- 1 -->
<div class="modal fade show" id="modal-cancel" style="display: none" aria-modal="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="cancel('hide')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 class="modal-title">取消確認</h4>
        <p>
          既にコードを発行済みの場合、<br>
          取消を行うと発行済みのコードは使用不可となります。<br>
          また、入金後の取消ではシステムでの返金は行われませんので、<br>
          運用でご対応ください。<br><br>
          取消を行いますか。</p>
        <div class="form-group">
          <label>取消理由</label>
          <label class="cancel-error">{{order_cancel_info_err}}</label>
          <input type="text" class="form-control" placeholder="請求先誤りの為" [(ngModel)]="order_cancel_info" maxlength="30">
        </div>
        <p>※取消理由はお客様の管理画面に表示されますので、<br><span>　記載内容には十分ご留意ください。</span></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="cancel('hide')">キャンセル</button>
        <button type="button" class="btn btn-primary" (click)="cancel('next')">取消</button>
      </div>
    </div>
  </div>
</div>
<!-- 2 -->
<div class="modal fade show" id="modal-payment" style="display: none" aria-modal="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="payment('hide')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 class="modal-title">入金消込確認</h4>
        <p>入金の消込処理を行いますか。</p>
        <label class="depositDate">入金日</label>
        <label class="cancel-error">{{deposit_date_err}}</label>
        <div class="form-group">
          <ngx-datepicker [(ngModel)]="depositDate" [options]="options" (click)="Calendar(1)" #date1>
          </ngx-datepicker>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-primary" (click)="payment('hide')">キャンセル</button>
        <button type="button" class="btn btn-primary" (click)="payment('next')">消込</button>
      </div>
    </div>
  </div>
</div>
<!-- 3 -->
<div class="modal fade show" id="modal-code" style="display: none" aria-modal="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="code('hide')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 class="modal-title">コード発行確認</h4>
        <p>
          コードを発行すると、登録されている企業アドレス宛に<br>
          メールで通知されます。<br><br>
          コード発行を行いますか。
        </p>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-primary" (click)="code('hide')">キャンセル</button>
        <button type="button" class="btn btn-primary" (click)="code('next')">発行</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade show" id="modal-complete" style="display: none" aria-modal="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="modalComplete()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 class="modal-title">{{completeTitle}}</h4>
        <p>{{completeMessage}}</p>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-primary" (click)="modalComplete()">閉じる</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade show" id="modal-error" style="display: none" aria-modal="true" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="modalError()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 class="modal-title">{{errorTitle}}</h4>
        <p>{{errorMessage}}</p>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-primary" (click)="modalError()">閉じる</button>
      </div>
    </div>
  </div>
</div>
<!-- end modals -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen]="true"><p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>></ngx-spinner>